import "@popperjs/core";
import * as bootstrap from "bootstrap";

addEventListener("DOMContentLoaded", () => {
  window.initializeBootstrapTooltip = (elem) => {
    elem.setAttribute("data-tooltip-initialized", true);
    return bootstrap.Tooltip.getOrCreateInstance(elem);
  };
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]:not([data-tooltip-initialized=true])'
  );
  Array.from(tooltipTriggerList).map((tooltipTriggerEl) =>
    window.initializeBootstrapTooltip(tooltipTriggerEl)
  );
  let event = new Event("BootstrapTooltipsReady");
  document.dispatchEvent(event);

  // create modal initializer method
  window.initializeBootstrapModal = (elem) => {
    elem.setAttribute("data-modal-initialized", true);
    return bootstrap.Modal.getOrCreateInstance(elem);
  };
  event = new Event("BootstrapModalsReady");
  document.dispatchEvent(event);
});
